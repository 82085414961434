import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | The Gents' Barber' Guild (Férfi borbélycéh)
			</title>
			<meta name={"description"} content={"Üdvözöljük a The Gents' Barber Guildben, a modern férfiak menedékében, akik nem csak egy hajvágásra, hanem egy élményre vágynak."} />
			<meta property={"og:title"} content={"Home | The Gents' Barber' Guild (Férfi borbélycéh)"} />
			<meta property={"og:description"} content={"Üdvözöljük a The Gents' Barber Guildben, a modern férfiak menedékében, akik nem csak egy hajvágásra, hanem egy élményre vágynak."} />
			<meta property={"og:image"} content={"https://unitedmastenga.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://unitedmastenga.com/img/595777.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://unitedmastenga.com/img/595777.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://unitedmastenga.com/img/595777.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://unitedmastenga.com/img/595777.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://unitedmastenga.com/img/595777.png"} />
			<meta name={"msapplication-TileImage"} content={"https://unitedmastenga.com/img/595777.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				The Gents' Barber
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Üdvözöljük a The Gents' Barber Guildben, a modern férfiak menedékében, akik nem csak egy hajvágásra, hanem egy élményre vágynak. Itt a klasszikus borbélyművészet találkozik a kortárs stílussal, mindezt egy nyugodt és férfias környezetben.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image src="https://unitedmastenga.com/img/1.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://unitedmastenga.com/img/2.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://unitedmastenga.com/img/3.jpg" width="320px" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-29">
			<Override
				slot="SectionContent"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
				flex-wrap="wrap"
				md-flex-direction="column"
				sm-min-width="280px"
			/>
			<Box width="20%" sm-width="100%" md-margin="0px 0px 36px 0px">
				<Text margin="0px 0px 0px 0px" font="--lead" color="--dark" sm-margin="0px 0px 20px 0px">
					Miért a The Gents' Barber Guild?
					<br />
				</Text>
			</Box>
			<Box width="80%" sm-width="100%" md-width="100%">
				<Box
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
				>
					<Text color="--dark" letter-spacing="-.7px" margin="0px 0px 8px 0px" font="--headline3">
						Szakértő kézművesség
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Szakképzett fodrászaink saját jogon művészek, akik az időtlen klasszikusoktól a legújabb trendekig számos stílusra specializálódtak.
						<br />
					</Text>
				</Box>
				<Box
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Személyre szabott szolgáltatás
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Hiszünk abban, hogy minden férfi egyedi ápolási élményt érdemel. Szolgáltatásainkat az Ön egyéni stílusához és preferenciáihoz igazítjuk.
					</Text>
				</Box>
				<Box
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Minőségi termékek
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Csak a legjobb termékeket használjuk és kínáljuk az Ön bőre és haja számára, a nyugtató aftershave-ektől kezdve a prémium ápolószerekig.
					</Text>
				</Box>
				<Box
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Nyugodt légkör
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Lépjen be egy olyan térbe, ahol kikapcsolódhat, élvezheti az ingyenes italokat, és maga mögött hagyhatja a külvilágot.
					</Text>
				</Box>
				<Box width="100%" border-color="--color-lightD2" margin="0px 0px 0px 0px">
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Közösségi fókusz
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Mi több vagyunk, mint egy fodrászat - mi vagyunk a központ, ahol az úriemberek összegyűlnek, történeteket osztanak meg egymással, és kapcsolatokat építenek.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});